// Styling for the call to action section
section.download {
    position: relative;
    .fill {
        min-height: 100%;
    }
    padding: 250px 0;
    background-image: url('../images/hands.jpg');
    background-position: center;
    @include background-cover;
    .download-content {
        position: relative;
        z-index: 1;
        h2 {
            font-size: 50px;
            margin-top: 0;
            margin-bottom: 25px;
            color: white;
        }
        @media (min-width: 768px) {
            h2 {
                font-size: 80px;
            }
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: fade-out(black, .5);
    }
}
