// Styling for the features section
.tutorial-item {
    margin: 0 auto;
    text-align: center;
    h3 {
        font-size: 30px;
    }
    i {
        font-size: 80px;
        display: block;
        margin-bottom: 15px;
        background: -webkit-linear-gradient(to left, $theme-secondary, $theme-tertiary);
        background: linear-gradient(to left, $theme-secondary, $theme-tertiary);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
.tutorial-row {
    margin-top: 15px;
}
